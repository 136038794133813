<script>
import { getCurrentBreakpoint } from '@voix/composables/useBreakpoints'
import { view } from 'drizzle-orm/sqlite-core'

export default {
  name: 'BookingHotels',

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    hotels: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      breakpoint: 1200,
    }
  },

  computed: {
    hotel: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },

    hotelsWithoutBeach() {
      return this.hotels.filter(hotel => hotel !== 449266)
    },

    label() {
      return this.hotel.name ? this.hotel.name : 'Search all hotels'
    },
    dropdownStyles() {
      if (
        this.breakpoint === 'md'
        || this.breakpoint === 'lg'
        || this.breakpoint === 'xl'
        || this.breakpoint === '2xl'
      ) {
        return { width: '625px', right: 0 }
      }

      return { width: '100%', right: 0 }
    },
    isInnerHeightShort() {
      if (window.innerHeight < 720)
        return true
      else
        return false
    },
  },
  watch: {
    hotels(newValue) {
      if (newValue.length === 1) {
        this.hotel = newValue[0]
        switch (newValue[0]) {
          case 446810:
            this.hotel = { id: 446810, name: 'The Cove' }
            break
          case 533303:
            this.hotel = { id: 533303, name: 'The Royal' }
            break
          case 449267:
            this.hotel = { id: 449267, name: 'The Coral' }
            break
          case 513177:
            this.hotel = { id: 513177, name: 'The Reef' }
            break
          case 449266:
            this.hotel = { id: 449266, name: 'The Beach' }
            break
          case 472864:
            this.hotel = { id: 472864, name: 'Harborside Resort' }
            break
        }
      }
    },
  },
  mounted() {
    this.breakpoint = getCurrentBreakpoint(window.innerWidth).name

    const hotelSlug = this.getHotelSlug()
    if (hotelSlug) {
      switch (hotelSlug) {
        case 'thecoveatlantis':
          this.hotel = { id: 446810, name: 'The Cove' }
          break
        case 'royaltowers':
          this.hotel = { id: 533303, name: 'The Royal' }
          break
        case 'coraltowers':
          this.hotel = { id: 449267, name: 'The Coral' }
          break
        case 'thereefatlantis':
          this.hotel = { id: 513177, name: 'The Reef' }
          break
        case 'beachtower':
          this.hotel = { id: 449266, name: 'The Beach' }
          break
        case 'harborsideresort':
          this.hotel = { id: 472864, name: 'Harborside Resort' }
          break
      }
    }
  },
  methods: {
    getHotelSlug() {
      const parts = window.location.pathname.split('/')
      let inRooms = false
      for (const i in parts) {
        if (inRooms)
          return parts[i]

        if (parts[i] === 'rooms')
          inRooms = true
      }
    },

    unsetHotel() {
      this.hotel = { id: null, name: null }
    },
  },
}
</script>

<template>
  <GlobalUICustomSelect
    class="flex"
    button-classes="text-left tracking-wide uppercase bg-white text-xs font-medium font-sans2 text-gray-600 w-full px-4 py-5 group-[.show-dropdown]:bg-orange-100 focus:outline-none relative"
    :dropdown-styles="dropdownStyles"
    open-on="click"
    :label="label"
    :close-on-select="true"
  >
    <div
      class="bg-orange-200 shadow-2xl w-full"
      :class="{
        'absolute -top-32': isInnerHeightShort,
      }"
    >
      <div class="grid grid-cols-2 xl:grid-cols-3 gap-0 bg-gray-200">
        <div
          v-if="hotels.includes(446810)"
          class="flex flex-col w-full md:w-auto border-r border-gray-300 border-b p-5 xl:p-10 xl:py-12 items-center justify-center bg-white cursor-pointer hover:bg-gray-200 transition-colors h-20 xl:h-40 duration-200 filter-bw"
          @click="hotel = { id: 446810, name: 'The Cove' }"
        >
          <LazyLogosCoveLogo class="w-24 h-24" />
        </div>

        <div
          v-if="hotels.includes(533303)"
          class="flex flex-col w-full md:w-auto xl:border-r border-gray-300 border-b p-10 xl:p-10 xl:py-12 items-center justify-center bg-white cursor-pointer hover:bg-gray-200 transition-colors h-20 xl:h-40 duration-200 filter-bw"
          @click="hotel = { id: 533303, name: 'The Royal' }"
        >
          <img
            src="/imgs/hotels/logos/royal.png"
            srcset="
              /imgs/hotels/logos/royal@2x.png 2x,
              /imgs/hotels/logos/royal@3x.png 3x
            "
            width="113"
            height="32"
          >
        </div>

        <div
          v-if="hotels.includes(449267)"
          class="flex flex-col w-full md:w-auto border-r xl:border-r-0 border-gray-300 border-b p-9 xl:p-10 xl:py-12 items-center justify-center bg-white cursor-pointer hover:bg-gray-200 transition-colors h-20 xl:h-40 duration-200 filter-bw"
          @click="hotel = { id: 449267, name: 'The Coral' }"
        >
          <img
            src="/imgs/hotels/logos/coral.png"
            srcset="
              /imgs/hotels/logos/coral@2x.png 2x,
              /imgs/hotels/logos/coral@3x.png 3x
            "
            width="112"
            height="34"
          >
        </div>

        <div
          v-if="hotels.includes(513177)"
          class="flex flex-col w-full md:w-auto xl:border-r border-gray-300 border-b p-8 xl:p-10 xl:py-12 items-center justify-center bg-white cursor-pointer hover:bg-gray-200 transition-colors h-20 xl:h-40 duration-200 filter-bw"
          @click="hotel = { id: 513177, name: 'The Reef' }"
        >
          <img
            src="/imgs/hotels/logos/reef.png"
            srcset="
              /imgs/hotels/logos/reef@2x.png 2x,
              /imgs/hotels/logos/reef@3x.png 3x
            "
            width="87"
            height="36"
          >
        </div>

        <!-- <div
          v-if="hotels.includes(449266)"
          class="flex flex-col w-full md:w-auto border-r border-gray-300 border-b p-8 xl:p-10 xl:py-12 items-center justify-center bg-white cursor-pointer hover:bg-gray-200 transition-colors h-20 xl:h-40 duration-200 filter-bw"
          @click="hotel = { id: 449266, name: 'The Beach' }"
        >
          <img
            src="/imgs/hotels/logos/beach.png"
            srcset="
              /imgs/hotels/logos/beach@2x.png 2x,
              /imgs/hotels/logos/beach@3x.png 3x
            "
            width="98"
            height="29"
          />
        </div> -->

        <div
          v-if="hotels.includes(472864)"
          class="flex flex-col w-full md:w-auto border-gray-300 border-b p-8 xl:p-10 xl:py-12 items-center justify-center bg-white cursor-pointer hover:bg-gray-200 transition-colors h-20 xl:h-40 duration-200 filter-bw"
          @click="hotel = { id: 472864, name: 'Harborside Resort' }"
        >
          <img
            src="/imgs/hotels/logos/harborside.png"
            srcset="
              /imgs/hotels/logos/harborside@2x.png 2x,
              /imgs/hotels/logos/harborside@3x.png 3x
            "
            width="98"
            height="29"
          >
        </div>
      </div>
      <div
        v-if="hotelsWithoutBeach.length > 1"
        class="p-4 pb-5 text-center"
        @click.prevent="unsetHotel"
      >
        <a
          href="#"
          class="text-glueblue-400 uppercase text-xs border-b border-glueblue-400 hover-draw-border"
          @click.prevent="unsetHotel"
        >See All</a>
      </div>
    </div>
  </GlobalUICustomSelect>
</template>
